import { Alert, AlertIcon, Box, Button, Container, Flex, Heading, Link, Text, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import type { InferGetServerSidePropsType } from "next";
import { AuthAction, withAuthUser } from "next-firebase-auth";
import { useRouter } from "next/router";
import { stringifyUrl } from "query-string";
import { FC, useCallback, useEffect, useState } from "react";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { AE, AnalyticsEnhancedConversionEvents } from "../@types/analytics";
import { sendEmailVerificationToUser } from "../components/EmailVerification";
import MetaTags from "../components/MetaTags";
import { TextInput } from "../components/TextInput";
import _c from "../configs/constants";
import Analytics from "../controllers/analytics_controller";
import { useForm } from "../hooks/useForm";
import api from "../services/root_service";
import { linkToCentralRedirect } from "../utils/link_util";
import { IGetServerSidePropsContext, withAppContext } from "../utils/ssr_util";
const googleProvider = new GoogleAuthProvider();
googleProvider.addScope("email");
googleProvider.addScope("profile");
const facebookProvider = new FacebookAuthProvider();
facebookProvider.addScope("email");
facebookProvider.addScope("public_profile");
interface LoginFormData {
  email: string;
  password: string;
}
interface ILoginPageProps extends InferGetServerSidePropsType<typeof fetchDataRequirements> {}
const LoginPage: FC<ILoginPageProps> = ({
  emailPrefill,
  destination
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [formSubmissionError, setFormSubmissionError] = useState<string | null>(null);
  useEffect(() => {
    Analytics.trackEvent(AE.Login_Start);
  }, []);
  const redirectToDestination = useCallback(() => {
    router.replace(stringifyUrl({
      url: "/client-redirect",
      query: {
        path: encodeURIComponent(linkToCentralRedirect(encodeURIComponent(destination), true, true))
      }
    }), undefined, {
      shallow: false
    });
  }, [destination, router]);
  const {
    data,
    errors,
    handleChange,
    handleSubmit
  } = useForm<LoginFormData>({
    initialValues: {
      email: emailPrefill ?? ""
    },
    validation: {
      email: {
        required: {
          value: true,
          message: "An email address is required."
        }
      },
      password: {
        required: {
          value: true,
          message: "A password is required."
        }
      }
    },
    onSubmit: async data => {
      setLoading(true);
      Analytics.trackEvent(AE.Login_ClickSubmit);
      try {
        await signInWithEmailAndPassword(getAuth(), data.email, data.password);
        Analytics.trackEvent(AE.Login_Finish);
        setTimeout(redirectToDestination, 1_500);
      } catch (e: any) {
        setLoading(false);
        if (e.code === "auth/popup-closed-by-user") {
          return;
        }
        if (e.code === "auth/user-not-found" || e.code === "auth/wrong-password") {
          setFormSubmissionError("We couldn't find a user with that email or password.");
        } else {
          setFormSubmissionError(e?.message);
        }
        Analytics.trackEvent(AE.Login_Error, {
          message: e?.message
        });
      }
    }
  });
  const handleSignInFirebase = (provider: "facebook" | "google") => {
    return async () => {
      const auth = getAuth();
      try {
        const result = await signInWithPopup(auth, provider === "facebook" ? facebookProvider : googleProvider);
        const {
          user,
          doesExistBefore
        } = await api().postRegisterFirebase({
          firebaseUser: result.user,
          timezone: dayjs.tz.guess(),
          // all new signups are snippet users moving forward
          snippetGeneratorTest: true
        });
        if (!user) {
          throw new Error("Error creating user");
        }

        /**
         * It's possible to create a new account on login page.
         * The events need to be fired if the user is new.
         */
        if (!doesExistBefore) {
          await sendEmailVerificationToUser(user.id);
          await Analytics.identifyUser(user, true);
          Analytics.trackEvent(AE.Signup_Finish, {
            email: user.email,
            isCreator: true,
            name: `${user.firstName} ${user.lastName}`,
            send_to: AnalyticsEnhancedConversionEvents[AE.Signup_Finish],
            user_data: {
              email: user.email
            }
          });
        }
        if (destination) {
          setTimeout(redirectToDestination, 1_000);
        }
      } catch (error) {
        setFormSubmissionError(error?.message);
      }
    };
  };
  return <Container display="flex" justifyContent="center" data-sentry-element="Container" data-sentry-component="LoginPage" data-sentry-source-file="login.tsx">
            <MetaTags title="Login" description="Securely log in to your LiveLink AI account to access personalised services, manage settings, and optimise your experience." data-sentry-element="MetaTags" data-sentry-source-file="login.tsx" />
            <Flex direction="column" pt={["150px", null, "200px"]} pb="100px" minWidth={{
      base: "300px",
      md: "400px"
    }} data-sentry-element="Flex" data-sentry-source-file="login.tsx">
                <Heading as="h1" fontSize="4xl" fontWeight="bold" data-sentry-element="Heading" data-sentry-source-file="login.tsx">
                    Login
                </Heading>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={6} mt={8} mb={4} data-sentry-element="VStack" data-sentry-source-file="login.tsx">
                        {router.query.password_reset_sent && <Alert status="success">
                                Click the link in your email to reset your password.
                            </Alert>}
                        <TextInput inputProps={{
            id: "email",
            name: "email",
            placeholder: "Email",
            value: data.email,
            onChange: handleChange("email"),
            autoComplete: "username",
            type: "email",
            isRequired: true,
            isDisabled: !!emailPrefill
          }} errorMessage={errors.email} data-sentry-element="TextInput" data-sentry-source-file="login.tsx" />
                        <TextInput inputProps={{
            id: "password",
            name: "password",
            placeholder: "Password",
            type: "password",
            value: data.password,
            onChange: handleChange("password"),
            isRequired: true
          }} autoFocus={!!emailPrefill} errorMessage={errors.password} data-sentry-element="TextInput" data-sentry-source-file="login.tsx" />
                        {formSubmissionError && <Alert status="error">
                                <AlertIcon />
                                {formSubmissionError}
                            </Alert>}

                        <Button type="submit" width="100%" size="lg" variant="primary" isLoading={loading} data-sentry-element="Button" data-sentry-source-file="login.tsx">
                            <Text as="span" color="currentcolor" data-sentry-element="Text" data-sentry-source-file="login.tsx">
                                Continue with email
                            </Text>
                        </Button>
                        <Link href={`/reset-password${emailPrefill ? `?email=${encodeURIComponent(emailPrefill)}` : ""}`} data-sentry-element="Link" data-sentry-source-file="login.tsx">
                            Forgot your password?
                        </Link>
                    </VStack>
                </form>
                <Flex alignItems="center" my={6} data-sentry-element="Flex" data-sentry-source-file="login.tsx">
                    <Box height="2px" backgroundColor="blackAlpha.200" flexGrow={1} data-sentry-element="Box" data-sentry-source-file="login.tsx" />
                    <Text fontSize={16} mx={2} data-sentry-element="Text" data-sentry-source-file="login.tsx">
                        or log in with
                    </Text>
                    <Box height="2px" backgroundColor="blackAlpha.200" flexGrow={1} data-sentry-element="Box" data-sentry-source-file="login.tsx" />
                </Flex>

                <Button width="100%" variant="whiteWithLightOutline" size="lg" mb={4} leftIcon={<FaGoogle />} onClick={handleSignInFirebase("google")} data-sentry-element="Button" data-sentry-source-file="login.tsx">
                    Continue with Google
                </Button>
                <Button width="100%" variant="whiteWithLightOutline" size="lg" leftIcon={<FaFacebook />} onClick={handleSignInFirebase("facebook")} data-sentry-element="Button" data-sentry-source-file="login.tsx">
                    Continue with Facebook
                </Button>
            </Flex>
        </Container>;
};
const fetchDataRequirements = async ({
  query
}: IGetServerSidePropsContext) => {
  const {
    email,
    destination
  } = query;
  return {
    props: {
      destination: (destination || "/clips") as string,
      emailPrefill: email ? decodeURIComponent(email as string) : null
    }
  };
};
export const getServerSideProps = withAppContext(fetchDataRequirements);
export default withAuthUser({
  whenAuthed: AuthAction.RENDER
})(LoginPage);