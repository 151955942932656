import { Box, HStack, Input, InputLeftAddon, InputProps, InputRightAddon, Text } from "@chakra-ui/react";
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { BaseInputGroup, InputGroupProps } from "./BaseInputGroup";
import { InputHeader } from "./InputHeader";
interface TextInputProps {
  inputProps: InputProps;
  errorMessage?: string;
  alwaysShowPlaceholder?: boolean;
  description?: string;
  hidePlaceholder?: boolean;
  inputGroupProps?: Partial<InputGroupProps>;
  hideTitleWhenSelected?: boolean;
  renderSuffix?: () => JSX.Element | null;
  autoFocus?: boolean;
}
export const TextInput = (props: TextInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const {
    renderSuffix = () => null
  } = props;
  const {
    onChange,
    ...inputProps
  } = props.inputProps;
  useEffect(() => {
    if (props.autoFocus && inputRef.current) {
      inputRef.current?.focus();
      setIsFocused(true);
    }
  }, [inputRef.current, props.autoFocus]);
  const isPlaceholderVisible = useMemo(() => {
    if (!props.inputProps.placeholder) {
      return false;
    }
    if (props.hidePlaceholder) {
      return false;
    }
    if (props.inputProps.value || isFocused) {
      return false;
    }
    if (props.inputProps.type === "date" || props.inputProps.type === "time") {
      return false;
    }
    return true;
  }, [props.inputProps, isFocused]);
  const inputHeaderVisible = useMemo(() => {
    return props.inputProps.value && !props.hideTitleWhenSelected && !props.hidePlaceholder;
  }, [props.inputProps.value, props.hidePlaceholder, props.hideTitleWhenSelected]);
  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) {
      return;
    }
    if (inputProps.type === "date" && event.target?.value === "") {
      return;
    }
    onChange(event);
  };
  return <Box w="100%" data-sentry-element="Box" data-sentry-component="TextInput" data-sentry-source-file="TextInput.tsx">
            <BaseInputGroup isDisabled={props.inputProps.isDisabled} {...props.inputGroupProps || {}} data-sentry-element="BaseInputGroup" data-sentry-source-file="TextInput.tsx">
                {props.description ? <InputLeftAddon background="none" border="none" justifyContent="left" alignItems="center" pl="var(--default-input-start-padding)" pr="3px" pt={inputHeaderVisible ? "11px" : undefined}>
                        <Box alignItems="center">
                            <Text fontSize={16} fontWeight="medium" letterSpacing={0.2} marginBottom={0}>{`${props.description}`}</Text>
                        </Box>
                    </InputLeftAddon> : null}
                <Box position="relative" display="flex" alignItems="center" width="100%" height="100%" data-sentry-element="Box" data-sentry-source-file="TextInput.tsx">
                    <Input ref={inputRef} {...inputProps} onChange={onChangeInput} value={props.inputProps.value ?? ""} border="none" pt={props.description ? "28px" : "33px"} pb={props.description ? "28px" : "23px"} px="var(--default-input-start-padding)" pl={props.description ? 0 : undefined} _placeholder={{
          opacity: 0
        }} _disabled={{
          backgroundColor: "#e2e2e2",
          cursor: "not-allowed"
        }} focusBorderColor="none" fontSize="16px" onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} data-sentry-element="Input" data-sentry-source-file="TextInput.tsx" />
                    {isPlaceholderVisible ? <HStack position="absolute" width="100%" height="100%" px={!props.description ? "var(--default-input-start-padding)" : undefined} pointerEvents={"none"}>
                            <Text color="blackAlpha.600" fontSize="16px" marginBottom={0}>
                                {props.inputProps.placeholder}
                            </Text>
                            {props.inputProps.isRequired ? <Text color="red" marginBottom={0}>
                                    *
                                </Text> : undefined}
                        </HStack> : null}
                </Box>
                {props.inputProps.maxLength ? <InputRightAddon background={props.inputProps.isDisabled ? "#e2e2e2" : "none"} border="none" justifyContent="left" alignItems="center" px="var(--default-input-start-padding)" cursor={props.inputProps.isDisabled ? "not-allowed" : "initial"}>
                        <Box alignItems="center">
                            <Text fontSize={16} color="#4C4C4C" opacity={0.5} marginBottom={0}>{`${props.inputProps.value && typeof props.inputProps.value === "string" ? props.inputProps.value.length : 0}/${props.inputProps.maxLength}`}</Text>
                        </Box>
                    </InputRightAddon> : null}
                {renderSuffix()}
                {!props.hideTitleWhenSelected && !props.hidePlaceholder && props.inputProps.placeholder ? <InputHeader title={props.inputProps.placeholder} isVisible={props.alwaysShowPlaceholder || !!props.inputProps.value || isFocused} /> : null}
            </BaseInputGroup>
            {props.errorMessage ? <Text fontSize={12} mt={1} color="red" marginBottom={0}>
                    {props.errorMessage}
                </Text> : null}
        </Box>;
};